/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import axios from 'axios';
import { Constant } from '../constant/Constant';
import { to, getQueryString } from './functional';

// const orderId = '62334088996167691';
// get(`/order/detail/${orderId}`).then((response) => {
//   console.log(response);
// }).catch((err: Error) => {
//   console.log(err);
// });

// 记得修改index.html文件 uma埋点的 appKey
const DEBUG = false; // 打版时改为 false
// const baseURL = Constant.DEBUG ? 'http://localhost' : '';

// 这里是本地启动的配置
const baseURL = DEBUG ? 'http://localhost' : '';
const prefix = '/api';

const timeout = 1000 * 60;

const instance = axios.create({
  // baseURL: 'https://player.runbo360field.com/api',
  // baseURL: 'http://localhost/api',
  // baseURL: 'http://59.110.68.216/api',
  baseURL: baseURL + prefix,
  // baseURL: baseURL,
  // "proxy": "https://api.runbo360field.com/api"
  // timeout,
  headers: {
    'Authorization': `Bearer ${getQueryString('token')}`,
  }
});




export async function post(url: string, params: any) {
  console.log(">>>>>>>>>>>>>>>>>>>>>>>", instance, ">>>>>>>>>>>>>>>>>>>>>>> post");

  const tuple = await to(instance.post(url, params));
  console.log(">>>>>>>>>>>>>>>>>>>>>>>", tuple, ">>>>>>>>>>>>>>>>>>>>>>> post");

  const response = postResponse(tuple);
  return response;
}

export async function postFormData(url: string, params: any) {
  console.log(">>>>>>>>>>>>>>>>>>>>>>>", instance, ">>>>>>>>>>>>>>>>>>>>>>> postFormData");

  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    formData.append(key, params[key]);
  });

  const tuple = await to(instance.post(url, formData, {
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${getQueryString('token')}`,
    },
  }));
  const response = postResponse(tuple);
  return response;
}

export async function get(url: string, params?: any) {
  console.log(">>>>>>>>>>>>>>>>>>>>>>>", instance, ">>>>>>>>>>>>>>>>>>>>>>> get");

  const tuple = await to(instance.get(url, { params }));
  console.log(">>>>>>>>>>>>>>>>>>>>>>>", tuple, ">>>>>>>>>>>>>>>>>>>>>>> get");

  const response = postResponse(tuple);
  return response;
}

export function isSuccess(response: any) {
  return response.ret_code === '00000000';
}

export function postResponse(tuple: any[]) {
  const [error, response] = tuple;
  if (error) {
    return [error, null];
  }
  const { data } = response;
  if (!isSuccess(data)) {
    return [data.ret_msg, data];
  }
  return [null, data.result || data.ret_code];
}
