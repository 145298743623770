/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import React from 'react';
import MPFloatView from '../components/float/mini-program/MPFloatView';
import { MPType, FooterType } from '../components/float/mini-program/index.d';
import OfficialFloatView from '../components/float/official/OfficialFloatView';
import DeviceView from '../components/float/device/DeviceView';
import { Channel } from './data';
import { getQueryString, normalPicture, panoPicture, panoVideo } from '../utils/functional';

export function ChannelDispatcher(props: any) {
  // 根据渠道, 展示不同视图.
  if (props.type === Channel.official) return (<OfficialFloatView />);
  if (props.type === Channel.device) return (<DeviceView />);

  const type = getQueryString('type');
  let footerType = FooterType.nothing;
  if (panoPicture()) {
    if (type === MPType.show)
      footerType = FooterType.button;
    else if (type === MPType.edit)
      footerType = FooterType.tab;
    else if (type === MPType.detail)
      footerType = FooterType.text;
    else if (type === MPType.default)
      footerType = FooterType.nothing;
    else if (type === MPType.panoPush)
      footerType = FooterType.push;
  }

  if (panoVideo()) {
    if (type === MPType.show)
      footerType = FooterType.button;
    else if (type === MPType.edit)
      footerType = FooterType.tab;
    else if (type === MPType.detail)
      footerType = FooterType.text;
    else if (type === MPType.default)
      footerType = FooterType.nothing;
    else if (type === MPType.panoPush)
      footerType = FooterType.push;
  }
  return (
    <>
      {
        panoPicture() ?
          <MPFloatView
            footerType={footerType}
          /> : panoVideo() ?
            <MPFloatView
              footerType={footerType}
            /> : null
      }

    </>
  );
}

export default ChannelDispatcher;
