/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useState, useEffect } from 'react';
import { Base64 } from 'js-base64';
import { executePanoAction, dynamicLoadPanoXML, reloadPanoXML, lockStickersAndTextStickers, unlockStickersAndTextStickers } from '../../../panoramic/PanoramicView';
import { ACTION_HIDE_ALL, ACTION_SHOW_ALL, hideVisualButton } from '../../../panoramic/actions';
import { setPanoData, getPanoData, transformToPanoData } from '../../../panoramic/data';
import {
  delay,
  getQueryString,
  fromShare,
  fromGiven,
  isTemplate,
  normalPicture,
  panoPicture,
  panoVideo,
} from '../../../../utils/functional';
import { fetchBackgroundMusicURL, fetchDataConfigById, fetchContentDetail, changeSkyTask, fetchGivenKey, getOriginImagePath } from '../../../../api/NetworkApi';
import { Destination, Wormhole } from '../../../../vendor/wormhole/index';
import { FooterType } from '../index.d';
import { createStrategy } from '../strategy';
import { SelectedTab } from '../../../tab/data/dataPicture';

export function useController(params: any, initialState?: any) {
  const { footerType } = params;
  const [showLoading, setShowLoading] = useState(false);
  const [showTextLoading, setShowTextLoading] = useState(false);
  const [textLoadingData, setTextLoadingData] = useState({});
  const [toast, setToast] = useState('');
  const [fullTips, setShowFullTips] = useState('');
  const [toastImage, setToastImage] = useState('');
  const [showButton, setShowButton] = useState(footerType === FooterType.button);
  const [getVr, setVr] = useState(false);
  const [cleanScreen, setCleanScreen] = useState(false);
  const [showQuitCleanButton, setShowQuitCleanButton] = useState(false);
  const [bgmItem, setBGMItem] = useState({} as any);
  const [bgmPlaying, setBGMPlaying] = useState(false);
  const [panoConfig, setPanoConfig] = useState(getPanoData());
  const [details, setDetails] = useState({});
  const [pushData, setPushData] = useState([]);
  const [orderData, setOrderData] = useState({});
  const [showShotCause, setShowShotCause] = useState(false);
  const [showContribution, setShowContribution] = useState(false); // 投稿
  const [menuHeight, setMenuHeight] = useState(0);
  console.log(footerType, '======== footerType ========');

  const tipControl = {
    showLoading: () => {
      setShowLoading(true);
    },
    dismissLoading: () => {
      setShowLoading(false);
    },
    showTextLoading: (title: string, image: string) => {
      setShowTextLoading(true);
      setTextLoadingData({
        title,
        image,
      })
    },
    hideTextLoading: () => {
      setShowTextLoading(false);
    },
    showToast: async (message: string, image?: any) => {
      setToast(message);
      setToastImage(image);
      await delay(3000);
      setToast('');
    },
    showFullTips: (message: string) => {
      setShowFullTips(message);
    },
  };

  useEffect(() => {
    // 注册隐藏 MusicControl 视图
    const bgmWormholePipe = Wormhole.getInstance().destination(
      Destination.UPDATE_BGM_ITEM,
      (bgmItem: any) => {
        if (bgmItem) {
          // setBGMItem({ ...bgmItem, shouldPlay: bgmItem.url ? true : false, });
          setBGMItem(bgmItem);
        }
      },
    );
    return () => {
      Wormhole.getInstance().close(bgmWormholePipe);
    };
  }, []);

  useEffect(() => {
    if (normalPicture()) return;

    // 玩图状态下, 可以编辑贴纸

    if (panoPicture()) {
      if (footerType === FooterType.tab) delay().then(() => unlockStickersAndTextStickers(SelectedTab.标签));
    }

    // 创建渲染策略
    const renderStrategy = createStrategy();
    // alert(`${JSON.stringify(renderStrategy)}..................................`)
    // 执行渲染策略
    renderStrategy.apply({
      tipControl,
      hooks: {
        setPanoConfig,
        setBGMItem,
        setDetails,
        setPushData,
        setOrderData,
      },
    });
  }, [footerType]);

  function handleClick() {
    if (panoPicture()) {
      console.log('修片handleClickhandleClickhandleClickhandleClickhandleClick');

      // 分享和模板不可编辑贴纸和文字贴纸
      // if (!fromShare() || !isTemplate()) delay().then(unlockStickersAndTextStickers);

      // 原片/作品/素材库 - 点击修片允许编辑标签
      unlockStickersAndTextStickers(SelectedTab.标签);
    }
    if (panoVideo()) {
      // executePanoAction('set(fullscreen,true)');
      executePanoAction(hideVisualButton());
    }

    setShowButton(false);
  }
  function handleNavigationButtonClick() {
    (window as any).wx.miniProgram.reLaunch({ url: '/pages/index/index?page=page2' });
  }
  async function handleAcceptPhotoButtonClick() {
    const id = getQueryString('orderId');
    tipControl.showLoading();
    const [error, result] = await fetchGivenKey({ id });
    tipControl.dismissLoading();
    if (error) return tipControl.showToast(error);

    const miniProgram = (window as any).wx.miniProgram;
    miniProgram.reLaunch({ url: '/pages/index/index?page=page2' });
    miniProgram.postMessage({
      data: [
        { type: 'given', key: result.share_id }
      ],
    });
  }
  function handleBGMItemClick(item: any) {
    if (item.value === 'no-bgm') {
      setBGMPlaying(false);
    } else {
      setBGMPlaying(true);
    }

    setBGMItem({ ...item, shouldPlay: true, });
  }
  async function handleBGMControlClick() {
    if (bgmPlaying) {
      Wormhole.getInstance().enter(Destination.UPDATE_BGM_ITEM, { ...bgmItem, shouldPlay: false, });
    } else {
      await delay(300);
      Wormhole.getInstance().enter(Destination.UPDATE_BGM_ITEM, { ...bgmItem, shouldPlay: true, });
    }
    setBGMPlaying(!bgmPlaying);
  }
  function handleCleanScreen(params?: String) {
    // 清屏时, 设置不可编辑. 分享和模板不可编辑贴纸和文字贴纸
    // if (!fromShare() || !isTemplate()) delay(200).then(lockStickersAndTextStickers);

    // 设置 pano 全屏
    Wormhole.getInstance().enter(Destination.UPDATE_PANO_STYLE, false);

    executePanoAction(ACTION_HIDE_ALL);

    setCleanScreen(true);
    if (params === 'vr') {
      setVr(true);
    }
  }
  (window as any).handleCleanScreen = handleCleanScreen;
  async function handlePanoClick() {
    setShowQuitCleanButton(true);
    await delay(2000);
    setShowQuitCleanButton(false);
  }
  function handleQuitCleanClick(params?: string) {
    // 退出清屏时, 设置可编辑. 分享和模板不可编辑贴纸和文字贴纸
    // if (!fromShare() || !isTemplate()) delay().then(unlockStickersAndTextStickers);

    executePanoAction(ACTION_SHOW_ALL);

    setShowQuitCleanButton(false);
    setCleanScreen(false);
    if (params === 'quitVr') {
      setVr(false);
    }
  }
  (window as any).funPanoClick = handlePanoClick;
  (window as any).handleQuitCleanClick = handleQuitCleanClick;

  // 重拍
  function handleShot() {
    setShowShotCause(!showShotCause);
  }

  // 投稿
  function handleContribution(str?: String) {
    setShowContribution(!showContribution);
    // 投稿成功点击-隐藏投稿按钮
    // alert(`${str} strstrdtdrdasdsadasdasdasfasfaddklfnjkargbaej`)
    if (str === 'success') {
      setOrderData({
        ...orderData,
        contribute_status_code: '02',
      });
    }
  }

  return {
    footerType,
    showLoading,
    toast,
    showTextLoading,
    textLoadingData,
    toastImage,
    showButton,
    showNavigationButton: fromShare(),
    showAcceptPhotoButton: fromGiven(),
    hideMenu: normalPicture(),
    cleanScreen,
    showQuitCleanButton,
    bgmItem,
    bgmPlaying,
    panoConfig,
    details,
    pushData,
    orderData,
    tipControl,
    showShotCause,
    menuHeight,
    fullTips,
    showContribution,
    getVr,

    setMenuHeight,
    handleClick,
    handleNavigationButtonClick,
    handleAcceptPhotoButtonClick,
    handleBGMItemClick,
    handleBGMControlClick,
    handleCleanScreen,
    handleQuitCleanClick,
    handleShot,
    setBGMItem,
    handleContribution,
  };
}
