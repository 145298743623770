/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import React from 'react';

export function ImageModal(props: any) {
  function handleClick() {
    props.onClose && props.onClose()
  }
  function handleContentClick(e: any) {
    e.stopPropagation()
  }
  return (
    props.visible ? 
    <div style={styles.container} onClick={handleClick}>
      <div style={styles.content} onClick={handleContentClick}>
        <img src={props.image} alt="" style={styles.image}/>
        <p style={styles.text}>{props.title}</p>
      </div>
    </div> :
    <></>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1001,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    backgroundColor: 'white',
    borderRadius: 2,
    boxShadow: '0 0 5px 1px rgba(0, 0, 0, 0.1)',
  },
  image: {
    width: 230,
    height: 230,
    opacity: 1,
    // marginTop: 30,
  },
  text: {
    width: 230,
    fontSize: 13,
    color: 'black',
    textAlign: 'center',
  }
};

export default ImageModal;
