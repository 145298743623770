/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import React from 'react';
import SizedBox from '../size-box/SizedBox';
import Checkbox from '../checkbox/Checkbox';
import RadioGroupPicture from '../radio/RadioGroup_p';
import RadioGroupVideo from '../radio/RadioGroup_v';
import { RadioShot, Radio } from '../radio/Radio';
import ImageButton from '../button/ImageButton';
import { getPanoData } from "../panoramic/data";
import { getQueryString, shouldShowDefault, shouldShowDetail, panoPicture, officialPicture, panoVideo, officialVideo, footerLayout } from '../../utils/functional';

export function MenuLayout(props: any) {
  const { orderData, menuHeight, type, showAcceptPhotoButton, showNavigationButton, handleContribution } = props;
  const deviceAppCode = getQueryString("deviceAppCode");
  const isDevice3 = deviceAppCode.indexOf('app_code_3');
  const objectFlag = getQueryString("objectFlag");
  const styleHeight = type === 'official' ? menuHeight :
    menuHeight ? { bottom: 60 + Number(menuHeight) } : { bottom: 125 };
  // console.log(orderData && orderData.repeat_flag && !showAcceptPhotoButton && !showNavigationButton, ' orderData && orderData.repeat_flag && !showAcceptPhotoButton && !showNavigationButton &&');

  return (
    <div style={{ ...styles.container, ...styleHeight }}>
      {
        orderData && orderData.repeat_flag && !showAcceptPhotoButton && !showNavigationButton &&
        <RadioShot onClick={props.onShot} icon="img/icon/again-shot.png" />
      }
      {/* 投稿 */}
      {
        isDevice3 === -1 && orderData && orderData.contribute_status_code === '01' && !showAcceptPhotoButton && !showNavigationButton && panoPicture() &&
        <>
          <SizedBox />
          <Radio style={styles.icon_tg} text="" icon="img/icon/tg-box.gif" onSelectedChange={handleContribution} />
        </>
      }
      {
        panoPicture() || officialPicture() ?
          <RadioGroupPicture deviceAppCode={deviceAppCode} orderData={orderData} selected={getPanoData().autorotate.type === '0'} /> : <></>
      }
      {
        panoVideo() || officialVideo() ?
          <RadioGroupVideo selected={getPanoData().jsonData.motion === '0'} /> : <></>
      }
      {/* 清屏按钮 */}
      {
        !(shouldShowDetail() || shouldShowDefault() || footerLayout() === "default") && isDevice3 === -1 && objectFlag == 'works' &&
        <>
          <SizedBox />
          <Radio text="清屏模式" icon="img/radio/ic-clean-screen1.png" onSelectedChange={props.onCleanScreen} />
        </>
      }
      {/* {
        objectFlag == 'works' &&
        <>
          <SizedBox />
          <Radio text="清屏模式" icon="img/radio/ic-clean-screen1.png" onSelectedChange={props.onCleanScreen} />
        </>
      } */}
      {/* 公众号小眼睛 */}
      {
        props.onFooterStatusChanged && officialPicture() &&
        <>
          <SizedBox style={{ height: 15 }} />
          <Checkbox icon="img/ic-hide-footer.png" highlightIcon="img/ic-show-footer.png" style={styles.footerIcon} onCheckedChange={props.onFooterStatusChanged} />
        </>
      }
    </div>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    bottom: 125,
    right: 0,
    width: 58,
    zIndex: 1000,
  },
  footerIcon: {
    width: 30,
    height: 20,
    paddingBottom: 5,
  },
  icon_tg: {
    width: 50,
    height: 50,
    marginRight: 5,
  },
};

export default MenuLayout;
