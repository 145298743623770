/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useState } from 'react';
import { executePanoAction } from '../panoramic/PanoramicView';
import {
  startBGM,
  stopBGM,
  saveBGM,
} from '../panoramic/actions';
import '../loading/loading.css';

export function MusicControl(props: any) {
  const { playing, item, } = props;
  function handleClick() {
    if (playing) {
      // 停止音乐
      executePanoAction(stopBGM());
    } else {
      // 开始音乐
      executePanoAction(startBGM(item.url));
    }

    props.onClick && props.onClick();
  }

  // const image = playing ? 'img/ic-play-checked.gif' : 'img/ic-player.png';
  const image = playing ? item.image : 'img/music/ic-player.png';
  return (
    item.value !== 'no-bgm' && props.title ?
    <div style={styles.container}>
      <img src="img/music/ic-musical.png" alt="" style={styles.musical} />
      <img className={playing ? 'music' : 'unMusic'} src="img/music/bg-player.png" alt="" style={styles.bgImage} />
      <img className={playing ? 'music' : ''} src={image} alt="" style={styles.playerImage} onClick={handleClick}/>
      {/* <p style={styles.text}>{props.title}</p> */}
    </div>
    : <></>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // background: 'rgba(250, 250, 250, 0.8)',
    paddingRight: 13,
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 5,
    // border: '1px solid #eee',
    borderLeft: 'none',
    // borderRadius: '0 50px 50px 0',
    position: 'absolute',
    top: 40,
    left: 0,
    zIndex: 1009,
  },
  bgImage: {
    width: 43,
    height: 43,
  },
  musical: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 20,
    height: 20,
  },
  playerImage: {
    position: 'absolute',
    borderRadius: 20,
    width: 18,
    height: 18,
  },
  text: {
    color: '#333',
    fontSize: 12,
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 58,
    marginTop: 5,
  }
};

export default MusicControl;
